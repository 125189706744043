<template>
    <div>
        <FullWidth id="home" class="home-search" :style="backgroundUrl">
            <div class="container"><TripSearch :stations="stations"></TripSearch></div>
        </FullWidth>
        <FullWidth class="home-support">
            <div class="d-flex align-items-center justify-content-between container" >
                <div class="col-sm-12">
                    <p v-if="getCompanyInfo" style="font-size: 2rem;" class="text-center text-white py-5 mb-0 font-weight-light">{{getCompanyInfo.website_content.support_text}}</p>
                    <p v-else style="font-size: 2rem;" class="text-center text-white py-5 mb-0 font-weight-light">
                      GET SUPPORT, HELP OR BOOK YOUR TICKET BY CALLING US AT: +88 01799 005747
                    </p>
                </div>
            </div>
        </FullWidth>
        <div>
            <PlaceSlider/>
        </div>
        <FullWidth>
            <BusFeatures/>
        </FullWidth>
        <FullWidth>
            <BusAmenities/>
        </FullWidth>
        <FullWidth class="home-we-served">
            <WeServed :stations="stations"/>
        </FullWidth>
        <FullWidth>
            <TopRoutes/>
        </FullWidth>
        <FullWidth>
            <MobileApp/>
        </FullWidth>
        <div>
            <AboutUs/>
        </div>
        <FullWidth v-if="getCompanyInfo.id==1" class="bg-aqua">
            <Management/>
        </FullWidth>
        <FullWidth class="home-contact-us">
            <ContactUs/>
        </FullWidth>
    </div>
</template>
<style lang="scss">
    .home-search {
        background-size: 100% auto !important;
        min-height: 650px !important;
    }
    @media screen and (min-width: 651px) and (max-width: 1024px) {
        .home-search {
            background-size: 100% auto !important;
            min-height: 400px !important;
        }
    }
    @media screen and (max-width: 650px) {
        .home-search {
            background-size: auto 100% !important;
            min-height: 600px !important;
        }
    }
    .home-support {
        background: #0790E8;
    }
    .home-we-served {
        background: #0D85D8;
    }
    .home-contact-us {
        background: #1e88e5;
    }
</style>

<script>
import etcService from '@/core/services/models/etc.service';
import {mapGetters} from 'vuex';

export default {
  name: "dashboard",
    data(){
      return{
        stations:[]
      }
    },
  components:{
      FullWidth: () => import('@/view/content/FullWidth.vue'),
      TripSearch: () => import('@/view/content/TripSearch.vue'),
      PlaceSlider: () => import('@/view/content/PlaceSlider.vue'),
      BusFeatures: () => import('@/view/content/BusFeatures.vue'),
      BusAmenities: () => import('@/view/content/BusAmenities.vue'),
      WeServed: () => import('@/view/content/WeServed.vue'),
      TopRoutes: () => import('@/view/content/TopRoutes.vue'),
      MobileApp: () => import('@/view/content/MobileApp.vue'),
      AboutUs: () => import('@/view/content/AboutUs.vue'),
      Management: () => import('@/view/content/Management.vue'),
      ContactUs: () => import('@/view/content/ContactUs.vue'),
  },

  computed:{
      ...mapGetters(['getCompanyInfo']),
      backgroundUrl(){
          let style = {
              'background': "url('"+this.getCompanyInfo.website_content.home_bg+"') center top no-repeat, #dee1ea",
          };
          return style;
      }
  },
  mounted() {
    etcService.getAllStations( s => {this.stations = [...s], e => this.$toasted.error("Could not fetch stations")})
  },
  methods: {
    log(){
     //console.log(this.getCompanyInfo)
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
<style scoped>

</style>
